import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import Terms from "@/views/CMS/termsAndConditions.vue";
import NewAccount from "@/views/Users/new-account.vue";
import Users from "@/views/Users/users.vue";
import Brokers from "@/views/Users/broker.vue";
import Tenants from "@/views/Users/tenants.vue";
import IP from "@/views/Users/ip.vue";
import SuperMasters from "@/views/Users/super-master.vue";
import SummaryReport from "@/views/SummaryReport/summary-report.vue";
import TradeSummaryReport from "@/views/Reports/trade-summary-report.vue";
import TradeBook from "@/views/Reports/trade-book-export.vue";
import TurnOverReport from "@/views/Reports/turn-over-export.vue";
import BranchSummaryReport from "@/views/Reports/branch-summary-report.vue";
import Orders from "@/views/SummaryReport/orders.vue";
// import Positions from "@/views/SummaryReport/positions.vue";
import PositionsV2 from "@/views/SummaryReport/positionsV2.vue";
import ComexOrders from "@/views/SummaryReport/comex-orders.vue";
import ComexPositions from "@/views/SummaryReport/comex-positions.vue";
import ComexTrades from "@/views/SummaryReport/comex-trades.vue";
import Brokerages from "@/views/Brokerages/brokerages.vue";
import Access from "@/views/Access/access.vue";
import AccessDenied from "@/views/Access/access-denied.vue";
import AllBrokerages from "@/views/Brokerages/all-brokerages.vue";
import AddBrokerage from "@/views/Brokerages/add-brokerage.vue";
import Masters from "@/views/Users/master.vue";
import Manager from "@/views/Users/manager.vue";
import MainView from "@/views/main.vue";
import MarketWatchComex from "@/views/MarketWatch/market-watch-comex.vue";
import MarketViewWeb from "@/views/MarketWatch/market-web.vue";
import Dashboard from "@/views/Dashboard/dashboard.vue";

import QuantitySetting from "@/views/Settings/quantity-setting.vue";
import MasterQuantitySetting from "@/views/Settings/master-quantity-setting.vue";
import BlockScript from "@/views/Settings/block-script.vue";
import BlockMasterScript from "@/views/Settings/block-master-script.vue";
import BlockIp from "@/views/Settings/block-ip.vue";
import MASTERSCRIPT from "@/views/Settings/master-scripts.vue";
import INACTIVEMASTERSCRIPT from "@/views/Settings/inactive-master-scripts.vue";
import Notifications from "@/views/Settings/notifications.vue";
import MasterNotifications from "@/views/Settings/master-notifications.vue";
import AddNotifications from "@/views/Settings/add-notifications.vue";
import ManageScripts from "@/views/Settings/manage-script.vue";
import TransferSetting from "@/views/Settings/transfer-setting.vue";
import TransferMasterSetting from "@/views/Settings/transfer-master-setting.vue";
import ClientSegmentSetting from "@/views/Settings/client-segment-setting.vue";
import MasterSegmentSetting from "@/views/Settings/master-segment-setting.vue";
import ClientOrderLimit from "@/views/Settings/client-order-limit.vue";
import MasterOrderLimit from "@/views/Settings/master-order-limit.vue";
import MasterScriptMarginLimit from "@/views/Settings/master-script-margin-limit.vue";
import ManagerScriptMarginLimit from "@/views/Settings/manager-script-margin-limit.vue";
import LedgerReport from "@/views/Reports/ledger-report.vue";
import MasterLedgerReport from "@/views/Reports/master-ledger-report.vue";
import UserSettlement from "@/views/Reports/user-settlement.vue";
import UserSettleTrades from "@/views/Reports/user-settle-trades.vue";
import UserSettleTradesApp from "@/views/Reports/user-settle-trades-app.vue";
import Settlement from "@/views/Reports/settlement.vue";
import EditAccountLog from "@/views/Reports/edit-account-log.vue";
import EditDeleteLog from "@/views/Reports/edit-delete-log.vue";
import RejectionLog from "@/views/Reports/rejection-log.vue";
import CashEntry from "@/views/Finance/cash-entry.vue";
import JvEntry from "@/views/Finance/jv-entry.vue";
import Setting from "@/views/Settings/setting.vue";
import ThemeSetting from "@/views/Settings/theme.vue";

const routes = [
  {
    path: "",
    redirect: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login/:type",
    name: "AdminLogin",
    component: Login,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/user-settle-trades-app/:id",
    name: "UserSettleTradesApp",
    component: UserSettleTradesApp,
  },
  {
    path: "/market-web-view",
    name: "market-web-view",
    component: MarketViewWeb,
  },
  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/add-brokerage/:client_id",
        name: "AddBrokerage",
        component: AddBrokerage,
      },
      {
        path: "/add-brokerages",
        name: "AddBrokerages",
        component: AddBrokerage,
      },
      {
        path: "/edit-brokerage/:id",
        name: "EditBrokerage",
        component: AddBrokerage,
      },
      {
        path: "/brokerages/:id",
        name: "Brokerages",
        component: Brokerages,
      },
      {
        path: "/access/:id",
        name: "Access",
        component: Access,
      },
      {
        path: "/all-brokerages",
        name: "All-Brokerages",
        component: AllBrokerages,
      },
      {
        path: "/setting",
        name: "Setting",
        component: Setting,
      },
      {
        path: "/theme-setting",
        name: "ThemeSetting",
        component: ThemeSetting,
      },
      {
        path: "/manage-scripts",
        name: "ManageScripts",
        component: ManageScripts,
      },
      {
        path: "/cash-entry",
        name: "CashEntry",
        component: CashEntry,
      },
      {
        path: "/jv-entry",
        name: "JvEntry",
        component: JvEntry,
      },
      {
        path: "/quantity-setting",
        name: "QuantitySetting",
        component: QuantitySetting,
      },
      {
        path: "/master-quantity-setting",
        name: "MasterQuantitySetting",
        component: MasterQuantitySetting,
      },
      {
        path: "/block-script",
        name: "BlockScript",
        component: BlockScript
      },
      {
        path: "/block-master-script",
        name: "BlockMasterScript",
        component: BlockMasterScript
      },
      {
        path: "/block-ip",
        name: "BlockIp",
        component: BlockIp
      },
      {
        path: "/transfer-setting",
        name: "TransferSetting",
        component: TransferSetting
      },
      {
        path: "/transfer-master-setting",
        name: "TransferMasterSetting",
        component: TransferMasterSetting
      },
      {
        path: "/client-segment-setting",
        name: "ClientSegmentSetting",
        component: ClientSegmentSetting
      },
      {
        path: "/master-segment-setting",
        name: "MasterSegmentSetting",
        component: MasterSegmentSetting
      },
      {
        path: "/client-order-limit",
        name: "ClientOrderLimit",
        component: ClientOrderLimit
      },
      {
        path: "/master-order-limit",
        name: "MasterOrderLimit",
        component: MasterOrderLimit
      },
      {
        path: "/manager-script-margin-limit",
        name: "ManagerScriptMarginLimit",
        component: ManagerScriptMarginLimit
      },
      {
        path: "/master-script-margin-limit/:id",
        name: "MasterScriptMarginLimit",
        component: MasterScriptMarginLimit
      },
      {
        path: "/summary-report",
        name: "SummaryReport",
        component: SummaryReport,
      },
      {
        path: "/trade-summary-report",
        name: "TradeSummaryReport",
        component: TradeSummaryReport,
      },
      {
        path: "/trade-export",
        name: "TradeExport",
        component: TradeBook,
      },
      {
        path: "/turn-over-report",
        name: "TurnOverReport",
        component: TurnOverReport,
      },
      {
        path: "/branch-summary-report",
        name: "BranchSummaryReport",
        component: BranchSummaryReport,
      },
      {
        path: "/ip",
        name: "IP",
        component: IP,
      },
      {
        path: "/manage-master-scripts",
        name: "MASTERSCRIPT",
        component: MASTERSCRIPT,
      },
      {
        path: "/inactive-script",
        name: "INACTIVEMASTERSCRIPT",
        component: INACTIVEMASTERSCRIPT,
      },
      {
        path: "/settlement",
        name: "Settlement",
        component: Settlement,
      },
      {
        path: "/notification/:id",
        name: "notification",
        component: Notifications,
      },
      {
        path: "/master-notifications",
        name: "MasterNotifications",
        component: MasterNotifications,
      },
      {
        path: "/add-notification",
        name: "Addnotification",
        component: AddNotifications,
      },
      {
        path: "/orders",
        name: "Orders",
        component: Orders,
      },
      {
        path: "/positions",
        name: "Positions",
        component: PositionsV2,
      },
      // {
      //   path: "/positionsV2",
      //   name: "PositionsV2",
      //   component: PositionsV2,
      // },
      {
        path: "/market-watch",
        name: "MarketWatchComex",
        component: MarketWatchComex,
      },
      {
        path: "/orders",
        name: "ComexOrders",
        component: ComexOrders,
      },
      {
        path: "/comex-positions",
        name: "ComexPositions",
        component: ComexPositions,
      },
      {
        path: "/trades",
        name: "ComexTrades",
        component: ComexTrades,
      },
      {
        path: "/new-account",
        name: "New Account",
        component: NewAccount,
      },
      {
        path: "/edit-account/:id",
        name: "Edit Account",
        component: NewAccount,
      },
      {
        path: "/users",
        name: "Users",
        component: Users,
      },
      {
        path: "/broker",
        name: "Brokers",
        component: Brokers,
      },
      {
        path: "/tenents",
        name: "Tenants",
        component: Tenants,
      },
      {
        path: "/masters",
        name: "Masters",
        component: Masters,
      },
      {
        path: "/manager",
        name: "Manager",
        component: Manager,
      },
      {
        path: "/super-masters",
        name: "Super Masters",
        component: SuperMasters,
      },
      {
        path: "/ledger-report",
        name: "LedgerReport",
        component: LedgerReport,
      },
      {
        path: "/master-ledger-report",
        name: "MasterLedgerReport",
        component: MasterLedgerReport,
      },
      {
        path: "/user-settlement/:name/:id",
        name: "UserSettlement",
        component: UserSettlement,
      },
      {
        path: "/master-user-settlement/:type/:name/:id",
        name: "MasterUserSettlement",
        component: UserSettlement,
      },
      {
        path: "/user-settle-trades/:name/:id",
        name: "UserSettleTrades",
        component: UserSettleTrades,
      },
      {
        path: "/edit-delete-log",
        name: "EditDeleteLog",
        component: EditDeleteLog,
      },
      {
        path: "/edit-account-log",
        name: "EditAccountLog",
        component: EditAccountLog,
      },
      {
        path: "/rejection-log",
        name: "RejectionLog",
        component: RejectionLog,
      },
      {
        path: "/access-denied",
        name: "access-denied",
        component: AccessDenied,
      },

    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      if (localStorage.getItem("token") != null) {
        next({
          path: "/dashboard",
          params: { nextUrl: to.fullPath },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }

});



export default router;
