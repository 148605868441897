<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <!-- <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600"
          >
            Default
          </h2> -->
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div class="col-lg-12 row">
              <div class="col-lg-2 mt-1">
                <Multiselect mode="single" trackBy="new_name" label="new_name" valueProp="id" class="register-select"
                  placeholder=" Client" v-model="user_id" :options="client_list" searchable="true" />
              </div>
              <div class="col-lg-1 mt-1 mr-3">
                <datepicker v-model="fromDate" class="form-control" inputFormat="dd-MM-yyyy" placeholder="From Date" />
              </div>
              <div class="col-1"></div>
              <div class="col-lg-1 mt-1 mr-3">
                <datepicker v-model="toDate" class="form-control" inputFormat="dd-MM-yyyy" placeholder="From Date" />
              </div>
              <div class="col-lg-2" style="margin-left: 100px; margin-top: 4px">
                <button id="btn-search" type="button" v-on:click="getList(1)" class="btn btn-primary mb-2">
                  Search
                </button>
                &nbsp;
                <button type="button" v-on:click="reset" class="btn btn-secondary mb-2">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row" style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <table id="table" style="margin-top: 16px" data-toggle="table" data-search="true" data-filter-control="true"
            data-toolbar="#toolbar" class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">Client</th>
                <th class="head">Updated By</th>
                <th class="head">Description</th>
                <th class="head">IP Address</th>
                <th class="head">Act Time</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td style="text-align: left" class="body">
                  {{ $helperService.getUpperCase(data.name + "-" + data.code) }}
                </td>
                <td class="body">
                  {{
                    data.user_type ? $helperService.getTitleCase(data.user_type) :
                    $helperService.getTitleCase(data.updated_by?.role)
                  }}
                </td>
                <td class="body">
                  {{ data.description }}
                </td>

                <td class="body">
                  {{ data.ip }}
                </td>

                <td class="body">
                  {{
                    data.created_at
                    ? $helperService.getDateTime(data.created_at)
                    : ""
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    ErrorComponent,
    Datepicker,
    Multiselect,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      type: "PENDING",
      list: [],
      fromDate: new Date(),
      count: 0,
      user_id: "",
      exchange: "",
      toDate: new Date(),
      client_list: [],
      exchanges: [
      {
          name: "COMEX",
          value: "COMEX",
        },
      ],
    };
  },
  mounted() {
     this.checkAccess();
    this.getClientList();
    this.getList(0);
  },
  methods: {
     checkAccess() {
      var access = JSON.parse(localStorage.getItem("access"));
      if (access.is_accountEditLog == 0) {
        this.$router.push("/access-denied");
      }
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = [];
          for (var i in res) {
            this.client_list.push({
              ...res[i],
              new_name: res[i].name + " (" + res[i].code + ")",
            });
          }
        })
        .catch(() => { });
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by);
    },
    getList(orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }
      if (this.trade_type) {
        searchObj.trade_action_type = this.trade_type;
      }
      if (this.name) {
        searchObj.name = this.name;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.authority_id) {
        searchObj.authority_id = this.authority_id;
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.user_id) {
        searchObj.user_id = this.user_id;
      }
      if (this.exchange) {
        searchObj.exchange = this.exchange;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      searchObj.trade_status = this.type;
      this.$api
        .getAPI({
          _action: "/account-log-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;

          res.list = this.list;
          this.count = this.list.length;

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = new Date();
      this.toDate = new Date();
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 10 + (index + 1)
        : index + 1;
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Broker",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/account/" + detail.id);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
