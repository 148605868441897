import moment from "moment";
class HelperService {
  constructor() {}
   sortingPrevent(orderBy, id) {
    var temp = document.getElementById(id)
    if (temp) {
      temp.classList.add('sorting_' + orderBy.toLowerCase())
    }

  }

  sorting(sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      var oldSorting = document.getElementsByClassName("sorting_asc");
      if (oldSorting.length > 0) {
        oldSorting[0].classList.remove("sorting_asc");
      } else {
        oldSorting = document.getElementsByClassName("sorting_desc");
        if (oldSorting.length > 0) {
          oldSorting[0].classList.remove("sorting_desc");
        }
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };
    return obj;
  }

  allowOnlyNumericValue(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }
  getDate(date) {
    return date ? moment(date).format("DD-MM-YYYY") : "-";
  }
  getDateTime(date) {
    return date ? moment(date).format("DD-MM-YYYY HH:mm:ss") : "-";
  }
  getTime(date) {
    return date ? moment(date).format("HH:mm:ss") : "-";
  }
  getTimeNew(date) {
    return date ? moment(date).format("HH:mm") : "-";
  }
  getCustomDate(date) {
    return date ? moment(date).format("DDMMM").toLocaleUpperCase() : "-";
  }
  getCustomDateNew1(date) {
    return date
      ? moment(date, "DDMMMYYY").format("DDMMM").toLocaleUpperCase()
      : "-";
  }
  getCustomDateNew2(date) {
    return date
      ? moment(date, "DD-MM-YYY").format("DDMMM").toLocaleUpperCase()
      : "-";
  }
  getCustomDateYear(date) {
    return date ? moment(date).format("DD MMM YYYY").toLocaleUpperCase() : "-";
  }

  getCustomDateYearnew2(date) {
    return date
      ? moment(date).format("DD MMM YYYY HH:mm:ss").toLocaleUpperCase()
      : "-";
  }
  getCustomDateYearNew(date) {
    return date ? moment(date).format("DD-MMM-YYYY").toLocaleUpperCase() : "-";
  }
  getCustomDateNew(date) {
    return date
      ? moment(date, "YYYY-MM-DD").format("DDMMM").toLocaleUpperCase()
      : "-";
  }
  checkMinAndMax(e) {
    let keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode < 48 || keyCode > 57) &&
      (keyCode !== 46 || e.target.value.indexOf(".") != -1)
    ) {
      e.preventDefault();
    }

    // restrict to 2 decimal places
    if (
      e.target.value != null &&
      e.target.value.indexOf(".") > -1 &&
      e.target.value.split(".")[1].length > 1
    ) {
      e.preventDefault();
    }

    if (e.target.value && parseFloat(e.target.value + e.key) > 100.0) {
      e.preventDefault();
      return false;
    }
  }
  showMenu(flag) {
    if (flag == "true") {
      document.getElementById("sidebar-div").style.width = "300px";
    } else {
      document.getElementById("sidebar-div").style.width = "0px";
    }
  }
  allowDecimalValue(e) {
    var charCode = e.which ? e.which : e.keyCode;
    var number = e.target.value.split(".");
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      e.preventDefault();
    }
    //get the carat position
    var caratPos = this.getSelectionStart(e.target);
    var dotPos = e.target.value.indexOf(".");
    if (caratPos > dotPos && dotPos > -1 && number[1].length > 1) {
      e.preventDefault();
    }
  }
  allowDecimalValueNew(e) {
    var charCode = e.which ? e.which : e.keyCode;
    var number = e.target.value.split(".");
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      e.preventDefault();
    }
    //get the carat position
    var caratPos = this.getSelectionStart(e.target);
    var dotPos = e.target.value.indexOf(".");
    if (caratPos > dotPos && dotPos > -1 && number[1].length > 3) {
      e.preventDefault();
    }
  }
  allowCharater(e) {
    if (
      (e.charCode > 64 && e.charCode < 91) ||
      (e.charCode > 96 && e.charCode < 123) ||
      e.charCode == 32
    ) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }
  allowNumber(e) {
    if (!/\d/.test(e.key) && e.key !== ".") {
      return e.preventDefault();
    }
  }
  allowWholeNumber(e) {
    if (!/\d/.test(e.key)) {
      return e.preventDefault();
    }
  }
  getTitleCase(value) {
    if (!value) {
      return "";
    } else {
      var str = "";
      var arr = value.split("_");
      for (var i in arr) {
        if (i == 0) {
          str =
            arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
        } else {
          str +=
            " " +
            arr[i].charAt(0).toUpperCase() +
            arr[i].substring(1).toLowerCase();
        }
      }
      return str;
    }
  }
  convert(num) {
    if (num) {
      num = parseInt(num);
      var a = [
        "",
        "One ",
        "Two ",
        "Three ",
        "Four ",
        "Five ",
        "Six ",
        "Seven ",
        "Eight ",
        "Nine ",
        "Ten ",
        "Eleven ",
        "Twelve ",
        "Thirteen ",
        "Fourteen ",
        "Fifteen ",
        "Sixteen ",
        "Seventeen ",
        "Eighteen ",
        "Nineteen ",
      ];
      var b = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];

      var str = "";
      if ((num = num.toString()).length > 9) {
        str = num.substr(0, num.length - 7);
        return str + " crore only";
      }

      var n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      str = "";
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
          : "";
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
          : "";
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
          : "";
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
          : "";
      str +=
        n[5] != 0
          ? (str != "" ? "and " : "") +
            (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
            "only "
          : "";
      return str;
    }
  }
  getUpperCase(value) {
    if (!value) {
      return "";
    } else {
      var str = value.toUpperCase();
      return str;
    }
  }

  getSelectionStart(o) {
    if (o.createTextRange) {
      var r = document.selection.createRange().duplicate();
      r.moveEnd("character", o.value.length);
      if (r.text == "") return o.value.length;
      return o.value.lastIndexOf(r.text);
    } else return o.selectionStart;
  }
  getupdated(value) {
    if (!value) {
      return "";
    } else {
      if (value == "NORMAL") {
        return "NRM";
      } else {
        return value;
      }
    }
  }

  getFormattedPrice(value, defaultValue) {
    if (!value) {
      return defaultValue || defaultValue == 0 ? defaultValue : "";
    } else {
      var str = parseFloat(value).toFixed(2);
      return str;
    }
  }

  getFormattedPriceCmx(value, defaultValue) {
    if (!value) {
      return defaultValue || defaultValue == 0 ? defaultValue : "";
    } else {
      var str = parseFloat(value);
      return str;
    }
  }

  getThousandConverter(num) {
    var n1, n2;
    num = num + "" || "";
    n1 = num.split(".");
    n2 = n1[1] ? n1[1].substr(0,2) : '';
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    return num;
  }

  splitCsv(str) {
    return str.split(",").reduce(
      (accum, curr) => {
        if (accum.isConcatting) {
          accum.soFar[accum.soFar.length - 1] += "," + curr;
        } else {
          accum.soFar.push(curr);
        }
        if (curr.split('"').length % 2 == 0) {
          accum.isConcatting = !accum.isConcatting;
        }
        return accum;
      },
      { soFar: [], isConcatting: false }
    ).soFar;
  }

  localSorting(arr, sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      var oldSorting = document.getElementsByClassName("sorting_asc");
      if (oldSorting.length > 0) {
        oldSorting[0].classList.remove("sorting_asc");
      } else {
        oldSorting = document.getElementsByClassName("sorting_desc");
        if (oldSorting.length > 0) {
          oldSorting[0].classList.remove("sorting_desc");
        }
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };

    if (obj.sort_by == "name" || obj.sort_by == "clientName") {
      obj.order_by == "ASC"
        ? arr.sort((a, b) => {
            if (a[obj.sort_by].toLowerCase() < b[obj.sort_by].toLowerCase()) {
              return -1;
            }
            if (a[obj.sort_by].toLowerCase() > b[obj.sort_by].toLowerCase()) {
              return 1;
            }
            return 0;
          })
        : arr
            .sort((a, b) => {
              if (a[obj.sort_by].toLowerCase() < b[obj.sort_by].toLowerCase()) {
                return -1;
              }
              if (a[obj.sort_by].toLowerCase() > b[obj.sort_by].toLowerCase()) {
                return 1;
              }
              return 0;
            })
            .reverse();
    } else {
      obj.order_by == "ASC"
        ? arr.sort((a, b) => {
            return a[obj.sort_by] - b[obj.sort_by];
          })
        : arr
            .sort((a, b) => {
              return a[obj.sort_by] - b[obj.sort_by];
            })
            .reverse();
    }

    return arr;
  }
}

export default new HelperService({});
