<template>
  <div v-show="isShowModal">
    <div class="sds-modal h-100 justify-content-center">
      <div class="sds-modal-body-new mx-auto">
        <div class="modal-header pt-0">
          <!-- <p class="modal-title">TRADE BOOK</p> -->
          <button type="button" class="close" v-on:click="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container form-signin3">
          <div class="row">
            <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
              <div class="col-lg-12 row ml-1">
                <p style="
                    color: black;
                    text-align: justify;
                    font-weight: bold;
                    font-size: 12px;
                    margin: 0px !important;
                    margin-top: 7px !important;
                  ">
                  Jobbing Trade
                </p>
                <div class="col-lg-2">
                  <!-- <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="value"
                    class="register-select"
                    placeholder="Time Frame"
                    v-model="minutes"
                    :options="timeFrame"
                    @select="showModal([], 'TYPE')"
                    searchable="true" /> -->
                  <Field type="text" class="form-control" name="minutes" placeholder="Minutes" v-model="minutes"
                    v-on:keypress="$helperService.allowWholeNumber($event)" />
                </div>
                <div class="col-lg-2">
                  <Field type="text" class="form-control" name="keyword" placeholder="SEARCH" v-model="keyword" />
                </div>
                <button id="btn-search" type="button" v-on:click="getList()" class="btn btn-primary mb-2">
                  Search
                </button>
                <div v-if="role == 'SUPER_MASTER' && is_edit == 1" class="col-lg-1 text-right">
                  <button v-on:click="revserseSelectedOne()" class="btn mr-2 cursor-pointer" style="
                      width: 100px !important;
                      margin-top: 0 !important;
                      font-size: 14px;
                      background-color: darkorange;
                      padding: 8px;
                    " id="delete-button" type="button">
                    Reverse Trade
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
            <div class="col-md-12 bg-light sticky-table-header" style="height: 400px">
              <table id="table" style="margin-top: 16px" data-toggle="table" data-search="true"
                data-filter-control="true" data-toolbar="#toolbar"
                class="table table-sm text-center text-white table-bordered product-list table-hover">
                <thead>
                  <tr>
                    <th class="head" style="width: 50px !important" v-if="role == 'SUPER_MASTER' && is_edit == 1">
                      <div class="switchToggleRead" style="text-align: start">
                        <input type="checkbox" v-model="isChecked" :true-value="true" :false-value="false"
                          v-on:change="checkall(isChecked)" />
                        <!-- Is Delete -->
                      </div>
                    </th>
                    <th class="head sorting" id="id-device" v-on:click="sorting('device', 'id-device')">
                      D
                    </th>
                    <th class="head sorting" id="id-name" v-on:click="sorting('id', 'id-name')">
                      Sr No.
                    </th>
                    <th class="head sorting" id="id-name" v-on:click="sorting('id', 'id-name')">
                      Trade No
                    </th>

                    <th class="head sorting" id="id-name" v-on:click="sorting('id', 'id-name')">
                      O/T
                    </th>
                    <th class="head sorting" id="id-order_type" v-on:click="sorting('id', 'id-order_type')">
                      Type
                    </th>
                    <!-- <th
                      class="head sorting"
                      id="id-trade_date"
                      v-on:click="sorting('trade_date', 'id-trade_date')">
                      Trade Date
                    </th> -->
                    <th class="head sorting" id="id-name" v-on:click="sorting('id', 'id-name')">
                      Client
                    </th>
                    <th class="head" id="id-eeee">I/O</th>
                    <!-- <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')">
                      Athority
                    </th> -->
                    <th class="head sorting" id="id-trading_symbol" v-on:click="
                      sorting('trading_symbol', 'id-trading_symbol')
                      ">
                      Script
                    </th>

                    <th class="head sorting" id="id-lot" v-on:click="sorting('lot', 'id-lot')">
                      Lot
                    </th>
                    <th class="head sorting" id="id-quantity" v-on:click="sorting('quantity', 'id-quantity')">
                      Qty
                    </th>

                    <th class="head sorting" id="id-price" v-on:click="sorting('price', 'id-price')">
                      Rate
                    </th>
                    <th class="head sorting" id="id-net_price" v-on:click="sorting('net_price', 'id-net_price')">
                      Net Rate
                    </th>
                    <th class="head sorting" id="id-name" v-on:click="sorting('id', 'id-name')">
                      User
                    </th>
                    <th class="head sorting" id="id-ip" v-on:click="sorting('ip', 'id-ip')">
                      IP
                    </th>
                    <!-- <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      O-Time
                    </th> -->
                    <th class="head sorting" id="id-trade_date" v-on:click="sorting('trade_date', 'id-trade_date')">
                      T-T
                    </th>
                  </tr>
                </thead>
                <tbody v-show="!loader">
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="body" v-if="role == 'SUPER_MASTER' && is_edit == 1">
                      <div class="switchToggleRead" style="text-align: start">
                        <input :id="'index_' + data.id" type="checkbox" v-model="data.isChecked"
                          v-on:change="checkHandlling(data.isChecked)" :true-value="true" :false-value="false" />
                      </div>
                    </td>
                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      <i v-if="data.device == 'WEB'" class="fa fa-globe" aria-hidden="true"></i>
                      <i v-if="data.device == 'MOBILE'" class="fa fa-mobile" aria-hidden="true"></i>
                      <i class="fa fa-user" v-if="data.device == 'ADMIN'" aria-hidden="true"></i>
                      <i class="fa fa-user" v-if="!data.device" aria-hidden="true"></i>
                    </td>
                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ index + 1 }}
                    </td>
                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.id }}
                    </td>

                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.order_type }}
                    </td>
                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      " :style="data.order_action == 'BUY'
                        ? 'color: blue;'
                        : 'color: red;'
                        ">
                      {{
                        data.order_type == "MARKET"
                          ? data.order_action
                          : data.order_type == "INTRADAY"
                            ? "Intraday Close"
                            : data.order_action + " " + data.order_type
                      }}
                    </td>
                    <!-- <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.created_at
                          ? $helperService.getDate(data.created_at)
                          : ""
                      }}
                    </td> -->
                    <td :title="data.user ? data.user.name : ''" style="text-align: left; cursor: pointer" :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.user
                          ? $helperService.getUpperCase(
                            data.user?.code + "-" + data.user?.name
                          )
                          : ""
                      }}
                    </td>
                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.new_type ? data.new_type : "-" }}
                    </td>
                    <!-- <td
                      :title="data.athority ? data.athority.name : ''"
                      style="text-align: left"
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.athority
                          ? $helperService.getUpperCase(
                              data.athority?.name + "-" + data.user?.code
                            )
                          : ""
                      }}
                    </td> -->
                    <td style="text-align: left" :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.trading_symbol ? data.trading_symbol : "" }}
                    </td>

                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.lot?.toFixed(2) }}
                    </td>
                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.quantity }}
                    </td>
                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.price }}
                    </td>
                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.net_price.toFixed(4) }}
                    </td>
                    <td style="text-align: left" :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.user
                          ? $helperService.getUpperCase(data.user?.name)
                          : ""
                      }}
                    </td>
                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{ data.ip }}
                    </td>

                    <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      ">
                      {{
                        data.trade_date
                          ? $helperService.getDateTime(data.trade_date)
                          : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sds-modal-background"></div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import moment from "moment";
import { Field } from "vee-validate";
// import Multiselect from "@vueform/multiselect";
// import Datepicker from "vue3-datepicker";
export default {
  name: "TextModal",
  components: {
    ErrorComponent,
    Field,
    // Multiselect,
    // Datepicker,
  },
  data() {
    return {
      id: "",
      role: localStorage.getItem("role"),
      is_edit: localStorage.getItem("is_edit"),
      isShowModal: false,
      searchObj: "",
      loader: true,
      list: [],
      data: [],
      keyword: "",
      fromDate: "",
      toDate: "",
      type: "",
      minutes: "",
      timeFrame: [
        {
          name: "5 MIN",
          value: 5,
        },
        {
          name: "10 MIN",
          value: 10,
        },
        {
          name: "15 MIN",
          value: 15,
        },
        {
          name: "20 MIN",
          value: 20,
        },
        {
          name: "30 MIN",
          value: 30,
        },
        {
          name: "45 MIN",
          value: 45,
        },
        {
          name: "1 hr",
          value: 60,
        },
      ],
    };
  },
  mounted() { },
  methods: {
    showModal(searchObj) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.isShowModal = true;
      this.searchObj = searchObj;
      this.list = [];
      this.getList();
    },

    filter() {
      this.showModal([], "TYPE");
      this.search();
    },
    search() {
      console.log("this.keyword", this.keyword);
      // if (this.keyword) {
      // this.list = this.data;
      this.list = this.list.filter((e) => {
        return (
          e.user?.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1 ||
          e.user?.code.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1 ||
          e.trading_symbol.toLowerCase().indexOf(this.keyword.toLowerCase()) >
          -1
        );
      });
      // }
    },
    sorting(sortBy, id) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.loader = true;
      var $this = this;
      setTimeout(() => {
        $this.list = $this.$helperService.localSorting($this.list, sortBy, id);
        $this.$refs.errorComponent.updateFormLoader(false);
        $this.loader = false;
      }, 1000);
    },
    getList() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      if (this.keyword) {
        this.searchObj.keyword = this.keyword;
      } else {
        this.searchObj.keyword = "";
      }
      this.$api
        .getAPI({
          _action: "/trade-list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.data = res;
          for (let k in this.data) {
            let type = this.data[k].type.split(",");
            this.data[k].new_type = type[0];
            this.data[k].type = type[1];
          }

          if (this.data && this.data.length > 0 && this.minutes) {
            for (let i = 0; i < this.data.length; i++) {
              var from_date = this.data[i].trade_date;
              var diff = 0;
              for (var j = i + 1; j < this.data.length; j++) {
                if (
                  this.data[i].user_id == this.data[j].user_id &&
                  this.data[i].instrument_token == this.data[j].instrument_token
                ) {
                  if ((this.data[i].order_action == "BUY" && this.data[j].order_action == "SELL") || (this.data[i].order_action == "SELL" && this.data[j].order_action == "BUY")) {
                    diff = moment(from_date).diff(
                      this.data[j].trade_date,
                      "minutes"
                    );
                    if (diff > 0 && diff <= this.minutes) {
                      if (this.list.find(
                        (e) => e.id === this.data[j].id
                      )) {
                        continue;
                      }

                      this.list.push(this.data[j]);
                      this.list.push(this.data[i]);
                    }
                  }
                }
              }
            }
          }
          res.list = this.list;

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    revserseSelectedOne() {
      this.$refs.errorComponent.updateFormLoader(true);
      var array = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          array.push(this.list[i].id);
          for (var j in this.data) {
            if (this.list[i].id == this.data[j].id) {
              this.data.splice(j, 1);
            }
          }
        }
      }
      var obj = {};
      obj.ids = array;

      if (array.length > 0) {
        this.$api
          .postAPI({
            _action: "/reverse-trade",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
              this.$refs.errorComponent.updateFormLoader(false);
              this.isChecked = false;
              this.showModal([], "TYPE");
            }
            this.getList();
          })
          .catch(() => {
            this.$refs.errorComponent.updateFormLoader(false);
          });
      } else {
        this.$notify({
          type: "error",
          text: "Select atleast on setting.",
        });
        this.$refs.errorComponent.updateFormLoader(false);
      }
    },
    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          this.list[k].isChecked = true;
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = -1;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
        console.log("object", count);
        if (count == 0) {
          this.isChecked = true;
        }
      }
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
