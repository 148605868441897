<template>
  <div v-show="isShowModal">
    <div class="sds-modal h-100 justify-content-center">
      <div class="mx-auto">
        <Form @submit="verifyOtp()" class="form-signin">
          <img class="mb-4" src="/images/logo.png" alt="" />
          <div class="text-center mb-4 digit-group">
            <Field
              id="codeBox1"
              type="text"
              name="otp1"
              class="text-center otp-control mr-1"
              maxlength="1"
              rules="required"
              @keypress="$helperService.allowNumber($event)"
              v-on:keyup="onKeyUpEvent(1, $event)"
              v-on:focus="onFocusEvent(1)"
            />
            <Field
              id="codeBox2"
              class="text-center otp-control mr-1"
              type="text"
              name="otp2"
              maxlength="1"
              rules="required"
              @keypress="$helperService.allowNumber($event)"
              v-on:keyup="onKeyUpEvent(2, $event)"
              v-on:focus="onFocusEvent(2)"
            />
            <Field
              id="codeBox3"
              class="text-center otp-control mr-1"
              type="text"
              name="otp3"
              maxlength="1"
              rules="required"
              @keypress="$helperService.allowNumber($event)"
              v-on:keyup="onKeyUpEvent(3, $event)"
              v-on:focus="onFocusEvent(3)"
            />
            <Field
              id="codeBox4"
              class="text-center otp-control mr-1"
              type="text"
              name="otp4"
              maxlength="1"
              rules="required:otp"
              @keypress="$helperService.allowNumber($event)"
              v-on:keyup="onKeyUpEvent(4, $event)"
              v-on:focus="onFocusEvent(4)"
            />
          </div>
          <ErrorMessage name="otp4" class="text-danger" />
          <div class="verification mb-4" style="margin-top: 16px; color: white">
            We will send verification code on your registerd mobile and email
          </div>
          <button
            class="btn btn-lg btn-primary btn-block"
            type="submit"
            id="verify-otp-button"
          >
            Verify Otp
          </button>
          <div class="verification" style="color: white">
            Didn’t Get the code?
            <a class="font-weight-bold"
              ><a
                style="cursor: pointer; color: white"
                id="resend-button"
                v-on:click="resendOtp()"
                >Resend</a
              ></a
            >.
          </div>
        </Form>
      </div>
    </div>
    <div class="sds-modal-background"></div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "OtpModal",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      isShowModal: false,
      endPoint: "",
    };
  },
  mounted() {},
  methods: {
    showModal() {
      this.isShowModal = true;
      if (!localStorage.getItem("token")) {
        this.endPoint = "/forgot";
      } else {
        this.endPoint = "";
      }
    },
    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length === 1) {
        if (index !== 4) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
      }
    },
    onFocusEvent(index) {
      for (var item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },
    resendOtp() {
      this.$api
        .postAPI({
          _action: "/users" + this.endPoint + "/resend",
          _buttonId: "resend-button",
        })
        .then(() => {})
        .catch(() => {});
    },
    verifyOtp() {
      var obj = {};
      obj.otp =
        document.getElementById("codeBox1").value +
        "" +
        document.getElementById("codeBox2").value +
        "" +
        document.getElementById("codeBox3").value +
        "" +
        document.getElementById("codeBox4").value;
      if (!localStorage.getItem("token")) {
        obj.username = localStorage.getItem("username");
        localStorage.setItem("otp", obj.otp);
      }

      this.$api
        .postAPI({
          _action: "/users" + this.endPoint + "/verify",
          _body: obj,
          _buttonId: "verify-otp-button",
        })
        .then((res) => {
          this.$notify({
             title: "Success",
                        type: "success",
            text: res.message,
          });
          if (!localStorage.getItem("token")) {
            this.$router.push("/reset-password");
          } else {
            localStorage.removeItem("isGuest");
            this.$router.push("/dashboard");
          }
        })
        .catch(() => {});
    },
  },
};
</script>