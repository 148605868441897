<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <!-- <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600"
          >
            Default
          </h2> -->
          <Form
            class="form-inline transparent-form p10 border-page m-t-2"
            ref="account"
            @submit="save()">
            <div class="col-lg-12 col-sm-12 row">
              <!-- <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start">
                  MARKET
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder="MARKET"
                  v-model="details.exchange"
                  :options="exchanges"
                  searchable="true"
                  @select="getScriptName(details.exchange)" />
                <ErrorMessage name="Name" class="text-danger" />
              </div> -->
              <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start">
                  SCRIPT NAME
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="name"
                  class="register-select"
                  placeholder="SCRIPT"
                  v-model="details.name"
                  :options="scriptList"
                  searchable="true" />
                <ErrorMessage name="Name" class="text-danger" />
              </div>
              <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start">
                  LOT SIZE
                  <span class="text-danger">*</span>
                </label>
                <Field
                  type="text"
                  class="form-control"
                  name="Lot"
                  v-model="details.lot_size"
                  placeholder="Lot"
                  v-on:keypress="
                    $helperService.allowOnlyNumericValue($event)
                  " />
              </div>

              <div class="col-lg-2 col-sm-2 mt-4">
                <button
                  class="save-btn"
                  style="width: 85px !important; margin-top: 0 !important"
                  id="save-button">
                  ADD
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div class="col-12 row">
            <div class="col-lg-6">
              <el-tabs
                v-model="activeName"
                style="max-width: 254px"
                class="demo-tabs"
                @tab-change="handleClick">
                <!-- :class="details.role == 'USER' && id ? 'col-3' : 'col-2'" -->
                <el-tab-pane label="Active Script" name="first" id="first-tab">
                </el-tab-pane>
                <!-- v-if="details.role == 'USER' && id" -->
                <el-tab-pane
                  label="Inactive Script"
                  name="second"
                  id="second-tab"></el-tab-pane>
              </el-tabs>
            </div>
            <div class="col-lg-2"></div>
            <div class="col-lg-2">
              <Field
                type="text"
                class="form-control"
                name="ledger_balance"
                placeholder="SEARCH"
                v-model="keyword"
                v-on:keyup="getList()" />
            </div>
            <div class="col-lg-2">
              <button
                v-on:click="deleteMultiple()"
                class="delete-btn mr-2 cursor-pointer"
                style="
                  width: 85px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                "
                id="delete-button"
                type="button">
                DELETE
              </button>
            </div>
          </div>
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head" style="width: 90px !important">
                  <div class="switchToggleRead" style="text-align: start">
                    <input
                      type="checkbox"
                      v-model="isChecked"
                      :true-value="true"
                      :false-value="false"
                      v-on:change="checkall(isChecked)" />
                    Is Delete
                  </div>
                </th>
                <th class="head">Sr No</th>
                <th
                  class="head sorting"
                  id="id-name"
                  v-on:click="sorting('name', 'id-name')">
                  SCRIPT NAME
                </th>
                <th class="head">Exchange</th>
                <th class="head">Lot Size</th>
                <th class="head">Action</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body">
                  <div class="switchToggleRead" style="text-align: start">
                    <input
                      :id="'index_' + data.id"
                      type="checkbox"
                      v-model="data.isChecked"
                      v-on:change="checkHandlling(data.isChecked)"
                      :true-value="true"
                      :false-value="false" />
                  </div>
                </td>
                <td class="body">{{ index + 1 }}</td>
                <td class="body">{{ data.name }}</td>
                <td class="body">
                  {{
                    data.exchange == "NFO"
                      ? "NSE"
                      : data.exchange
                      ? data.exchange
                      : ""
                  }}
                </td>
                <td class="body">{{ data.lot_size ? data.lot_size : "-" }}</td>
                <td class="body">
                  <a
                    v-on:click="deleteSelecOne(data.id)"
                    class="fa fa-trash text-danger"
                    style="cursor: pointer"
                    title="Delete"></a>
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/ErrorComponent";
import swal from "sweetalert2";
export default {
  components: {
    Form,
    Field,
    ErrorComponent,
    ErrorMessage,
    Multiselect,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      activeName: "first",
      isChecked: false,
      keyword: "",
      list: [],
      exchanges: [
        // {
        //   name: "NSE",
        //   value: "NFO",
        // },
        // {
        //   name: "MCX",
        //   value: "MCX",
        // },
        {
          name: "COMEX",
          value: "COMEX",
        },
      ],
      scriptList: [],
      details: {
        ip: "",
      },
    };
  },
  mounted() {
     this.getScriptName("COMEX");
    this.getList();
  },
  methods: {
    handleClick() {
      if (this.activeName == "second") {
        this.$router.push("/inactive-script");
      }
    },
    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          this.list[k].isChecked = true;
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = -1;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
        console.log("object", count);
        if (count == 0) {
          this.isChecked = true;
        }
      }
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by);
    },
    getScriptName(exchange) {
      this.scriptList = [];
      if (exchange) {
        this.$api
          .getAPI({
            _action: "/all-script-list/" + exchange,
          })
          .then((res) => {
            this.scriptList = res;
          })
          .catch(() => {});
      }
    },
    getList(orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      } else {
        searchObj.sort_by = "name";
        searchObj.order_by = "ASC";
      }

      this.$api
        .getAPI({
          _action: "/master-script-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          // res.list = this.list;
          for (var i in this.list) {
            this.list[i].isChecked = false;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    save() {
      this.$api
        .postAPI({
          _action: "/add-master-script",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
            this.$refs.account.resetForm();
            this.getList();
          }
        })
        .catch(() => {});
    },

    deleteSelecOne(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to delete script",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .postAPI({
                _action: "/master-script/" + id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  this.getList();
                }
              })
              .catch(() => {});
          } else {
            this.getList();
          }
        });
    },
    deleteMultiple() {
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to delete scripts",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var array = [];
            for (var i in this.list) {
              if (this.list[i].isChecked) {
                array.push(this.list[i].id);
              }
            }
            var obj = {};
            obj.ids = array;

            if (array.length > 0) {
              this.$api
                .postAPI({
                  _action: "/delete-master-script",
                  _body: obj,
                })
                .then((res) => {
                  if (res) {
                    this.$notify({
                      title: "Success",
                      type: "success",
                      text: res.message,
                    });
                    this.getList();
                  }
                })
                .catch(() => {});
            }
          } else {
            this.getList();
          }
          
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
