<template>
  <div>
    <div class="container form-signin3">
      <el-tabs
        v-model="activeName"
        style="max-width: 460px"
        class="col-3 demo-tabs ml-4"
        @tab-change="handleClick">
        <el-tab-pane label="Basic Information" name="first" id="first-tab">
        </el-tab-pane>
        <el-tab-pane
          label="Brokerage"
          name="second"
          id="second-tab"></el-tab-pane>
        <el-tab-pane
          v-if="id"
          label="Script Wise Margin"
          name="third"
          id="third-tab"></el-tab-pane>
      </el-tabs>
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <!-- <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600"
          >
            Default
          </h2> -->
          <Form ref="scriptMargin" @submit="save()">
            <div class="col-12 form row mb-2 border-page">
              <!-- <div
                class="col-12 bg-transparent card-header text-left"
                style="margin: 0; padding: 9px">
                <h5 style="color: black !important; margin: 0; padding: 0">
                  Brokerage
                </h5>
              </div> -->
              <div class="col-2" style="text-align: left" v-if="!id">
                <label class="col-form-label" style="display: flex">
                  Master
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  class="register-select"
                  placeholder="Master"
                  v-model="details.user_id"
                  :options="master_list"
                  @select="getList(details.user_id)"
                  searchable="true" />
              </div>

              <div class="col-4" style="text-align: left">
                <label class="col-form-label" style="display: flex">
                  Script
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="tags"
                  trackBy="name"
                  label="name"
                  valueProp="name"
                  class="multi-select"
                  style="
                    max-height: 200px !important;
                    min-height: 34px !important;
                  "
                  placeholder="Script"
                  v-model="details.instrument_token"
                  :options="script_list"
                  searchable="true" />
              </div>
              <div class="col-1" style="justify-content: flex-start">
                <label class="col-form-label" style="display: flex">
                  % (Wise)
                </label>
                <input
                  id="percent_margin"
                  style="width: 13px; margin-right: 44px"
                  type="checkbox"
                  name="margin_type"
                  v-model="details.margin_type"
                  true-value="PERCENT" />
              </div>
              <div class="col-1" style="justify-content: flex-start">
                <label class="col-form-label" style="display: flex">
                  Fix
                </label>
                <input
                  id="fix_margin"
                  style="width: 13px; margin-right: 44px"
                  type="checkbox"
                  name="margin_type"
                  v-model="details.margin_type"
                  true-value="FIX" />
              </div>
              <div class="col-2">
                <label class="col-form-label" style="display: flex">
                  {{ details.margin_type == "FIX" ? "Fix" : "%" }} Margin
                  <span class="text-danger">*</span>
                </label>
                <Field
                  type="text"
                  class="form-control"
                  name="margin"
                  placeholder="0"
                  autocomplete="off"
                  :validateOnInput="true"
                  rules="required"
                  v-model="details.margin"
                  v-on:keypress="$helperService.allowDecimalValueNew($event)" />
                <p
                  style="
                    text-align: left;
                    color: black;
                    font-size: 13px;
                    display: flex;
                    min-width: 100%;
                    margin-top: 10px;
                  ">
                  <ErrorMessage name="margin" class="text-danger" />
                </p>
                <span
                  v-if="details.margin"
                  style="
                    text-align: left;
                    color: black;
                    font-size: 13px;
                    display: flex;
                    min-width: 100%;
                  ">
                  {{ $helperService.convert(details.margin) }}
                </span>
              </div>
              <div class="col-2 text-center">
                <button
                  class="save-btn px-4 ml-3"
                  type="submit"
                  id="save-button">
                  SAVE
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div class="col-12 row">
            <div class="col-lg-4">
              <Field
                type="text"
                class="col-lg-3 form-control"
                name="ledger_balance"
                placeholder="SEARCH"
                v-model="keyword"
                v-on:keyup="getList()" />
            </div>
          
            <div class="col-lg-1 text-right">
              <button
                v-on:click="deleteSelecOne()"
                class="delete-btn mr-2 cursor-pointer"
                style="
                  width: 90px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                  height: 27px;
                "
                id="delete-button"
                type="button">
                DELETE
              </button>
            </div>
          </div>
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">
                  <div class="switchToggleRead" style="text-align: start">
                    <input
                      type="checkbox"
                      v-model="isChecked"
                      :true-value="true"
                      :false-value="false"
                      v-on:change="checkall(isChecked)" />
                    Is Delete
                  </div>
                </th>
                <th
                  class="head sorting"
                  id="id-Client"
                  v-on:click="sorting('users.name', 'id-Client')">
                  Manager Name
                </th>
                <th
                  class="head sorting"
                  id="id-scripts"
                  v-on:click="sorting('instrument_token', 'id-scripts')">
                  Symbol
                </th>

                <th
                  class="head sorting"
                  id="id-margin_type"
                  v-on:click="sorting('margin_type', 'id-margin_type')">
                  Fix/Percent
                </th>
                <th
                  class="head sorting"
                  id="id-margin"
                  v-on:click="sorting('margin', 'id-margin')">
                  Margin
                </th>
                <th
                  class="head sorting"
                  id="id-created_at"
                  v-on:click="sorting('created_at', 'id-created_at')">
                  Add Time
                </th>
                <!-- <th class="head">Delete</th> -->
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body" style="text-align: start">
                  <div class="switchToggleRead">
                    <input
                      :id="'index_' + data.id"
                      type="checkbox"
                      v-model="data.isChecked"
                      v-on:change="checkHandlling(data.isChecked)"
                      :true-value="true"
                      :false-value="false" />
                  </div>
                </td>
                <td class="body">{{ data.user ? data.user.name : "" }}</td>
                <td class="body">
                  {{ data.instrument_token }}
                </td>

                <td class="body">{{ data.margin_type }}</td>
                <td class="body">{{ data.margin }}</td>
                <td class="body">
                  {{ $helperService.getDateTime(data.created_at) }}
                </td>

                <!-- <td class="body">
                  <a
                    v-on:click="showDeletePopup(data, index)"
                    class="fa fa-trash text-danger"
                    style="cursor: pointer"
                    title="Delete"
                  ></a>
                </td> -->
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    ErrorComponent,
    Multiselect,
    Field,
    Form,
    ErrorMessage,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      isChecked: false,
      keyword: "",
      activeName: "third",
      type: "Default",
      list: [],
      details: {
        exchange: "COMEX",
        margin: "",
        user_id: "",
        margin_type: "",
        instrument_token: [],
      },
      master_list: [],
      script_list: [],
      exchanges: [
      {
          name: "COMEX",
          value: "COMEX",
        },
      ],
      fromDate: "",
      toDate: "",
    };
  },
  mounted() {
    if (this.id) {
      this.details.user_id = this.id;
    }
    this.getMasterList(0);
    this.getList();
    this.getScriptList("COMEX")
  },
  methods: {
    handleClick() {
      if (this.activeName == "first") {
        this.$router.push("/edit-account/" + this.id);
      } else if (this.activeName == "second") {
        this.$router.push("/brokerages/" + this.id);
      }
    },
    clear() {
      this.details.exchange = "COMEX";
      this.getList();
    },
    getScriptList(exchange) {
      this.details.instrument_token = [];
      this.script_list = [];
      this.$refs.errorComponent.updateFormLoader(true);
      var searchObj = {};
      if (exchange) {
        searchObj.exchange = exchange;
      }
      this.$api
        .getAPI({
          _action: "/script-list-by-exchange",
          _body: searchObj,
        })
        .then((res) => {
          this.script_list.push({ instrument_token: "ALL", name: "ALL" });
          for (var i in res) {
            this.script_list.push(res[i]);
          }
          this.getList();
          this.$refs.errorComponent.updateFormLoader(false);
        })
        .catch(() => {});
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.master_list = res;
          for (var i in this.master_list) {
            this.master_list[i].new_name =
              this.master_list[i].name + " (" + this.master_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          this.list[k].isChecked = true;
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = -1;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
        console.log("object", count);
        if (count == 0) {
          this.isChecked = true;
        }
      }
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by);
    },
    getList(orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.details.user_id) {
        searchObj.user_id = this.details.user_id;
      }

      if (this.details.exchange) {
        searchObj.exchange = this.details.exchange;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/script-margin-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          res.list = this.list;
          for (var i in this.list) {
            this.list[i].isChecked = false;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    deleteSelecOne() {
      this.$refs.errorComponent.updateFormLoader(true);
      var array = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          array.push(this.list[i].id);
        }
      }
      var obj = {};
      obj.ids = array;

      if (array.length > 0) {
        this.$api
          .postAPI({
            _action: "/delete-script-margin",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
              this.$refs.errorComponent.updateFormLoader(false);
              this.getList();
            }
          })
          .catch(() => {
            this.$refs.errorComponent.updateFormLoader(false);
          });
      } else {
        this.$notify({
          type: "error",
          text: "Select atleast on setting.",
        });
        this.$refs.errorComponent.updateFormLoader(false);
      }
    },
    save() {
      this.$api
        .postAPI({
          _action: "/add-edit-script-margin",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
          }
          var user_id = this.details.user_id;
          this.details.user_id = user_id;
          this.details.exchange = "COMEX";
          this.details.instrument_token = [];
          // this.details.margin = "";
          this.$refs.scriptMargin.resetForm();
          this.getList(0);
        })
        .catch(() => {});
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
