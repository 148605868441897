<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div
          class="col-lg-12 col-sm-12 row"
          style="padding-left: 50px; padding-right: 50px"
        >
          <!-- <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600"
          >
            Default
          </h2> -->
          <Form
            class="form-inline transparent-form p10 border-page m-t-2"
            ref="account"
            @submit="save()"
          >
            <div class="col-lg-12 col-sm-12 row">
              <div class="marketUser3 mr-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start"
                >
                  Client
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="multiple"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder=" Client"
                  v-model="detail.user_ids"
                  :options="client_list"
                  @select="getList()"
                  searchable="true"
                />
              </div>
             
              <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start"
                >
                  Symbol
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  :disabled="detail.range == 1"
                  valueProp="name"
                  class="register-select"
                  placeholder="Script"
                  v-model="detail.instrument_token"
                  :options="script_list"
                  searchable="true"
                />
              </div>

              <div class="col-lg-3 col-md-4 col-sm-4 row marketUser2">
                <div class="col-lg-5 col-md-6 col-sm-6">
                  <div
                    class="mt-2 row ml-2"
                    style="font-size: 12px; font-weight: 550 !important"
                  >
                    <input
                      id="is_broker_gross"
                      name="is_broker_gross"
                      type="checkbox"
                      v-on:change="emptyValue()"
                      v-model="detail.range"
                      class="ml-1"
                      :true-value="1"
                      :false-value="0"
                    />
                    <label class="col-form-label" style="display: flex"
                      ><span class="ml-2">Range </span></label
                    >
                  </div>
                  <Field
                    name="Start"
                    type="number"
                    :disabled="detail.range == 0"
                    :style="detail.range == 0 ? 'cursor:no-drop;' : ''"
                    v-model="detail.start_range"
                    class="form-control input-width"
                    placeholder="Start"
                  />
                </div>
                <div class="col-lg-5 col-md-6 col-sm-6 mt-4">
                  <label class="col-form-label" style="display: flex"></label>
                  <Field
                    name="End"
                    type="number"
                    :disabled="detail.range == 0"
                    v-model="detail.end_range"
                    :style="detail.range == 0 ? 'cursor:no-drop;' : ''"
                    class="form-control input-width mt-3"
                    placeholder="End"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-2 col-xl-auto handle-radio">
                <div class="custom-control custom-radio mb-1">
                  <Field
                    v-model="detail.type"
                    type="radio"
                    value="QTY"
                    id="is_broker_gross5"
                    name="is_broker_gross5"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important"
                  />
                  <label
                    style="font-size: 13px; padding-top: 5px"
                    for="is_broker_gross5"
                    class="custom-control-label mt-1 order-radio-lable"
                    >Qty</label
                  >
                </div>
                <div class="custom-control custom-radio mb-1">
                  <Field
                    v-model="detail.type"
                    type="radio"
                    value="LOT"
                    id="is_broker_gross3"
                    name="is_broker_gross3"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important"
                  />
                  <label
                    style="font-size: 13px; padding-top: 5px"
                    for="is_broker_gross3"
                    class="custom-control-label mt-1 order-radio-lable"
                    >Lot</label
                  >
                </div>
                <div class="custom-control custom-radio mb-1">
                  <Field
                    v-model="detail.type"
                    type="radio"
                    value="VALUE"
                    id="is_broker_gross4"
                    name="is_broker_gross4"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important"
                  />
                  <label
                    style="font-size: 13px; padding-top: 5px"
                    for="is_broker_gross4"
                    class="custom-control-label mt-1 order-radio-lable"
                    >Value</label
                  >
                </div>
              </div>
              <div class="col-lg-1 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start"
                >
                  Position Limit
                  <span class="text-danger">*</span>
                </label>
                <Field
                  name="positionLimit"
                  type="number"
                  v-model="detail.position_limit"
                  class="form-control input-width"
                />
              </div>
              <div class="col-lg-1 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start"
                >
                  Max Order
                  <span class="text-danger">*</span>
                </label>
                <Field
                  name="maxOrder"
                  type="number"
                  v-model="detail.max_order"
                  class="form-control input-width"
                  value="0"
                />
              </div>
              <div class="col-lg-1 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start"
                >
                  Min Order
                  <span class="text-danger">*</span>
                </label>
                <Field
                  name="minOrder"
                  type="number"
                  v-model="detail.min_order"
                  class="form-control input-width"
                  value="0"
                />
              </div>
              <div class="col-lg-1 mt-4">
                <button
                  class="save-btn"
                  style="width: 85px !important; margin-top: 0 !important"
                  id="save-button"
                  type="button"
                  v-on:click="save()"
                >
                  ADD
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px"
      >
        <div class="col-md-12 bg-light border-page">
          <div class="col-12 row">
            <div class="col-lg-6">
              <Field
                type="text"
                class="col-lg-3 form-control"
                name="ledger_balance"
                placeholder="SEARCH"
                v-model="keyword"
                v-on:keyup="getList()"
              />
            </div>
            <div class="col-lg-6 text-right">
              <button
                v-on:click="deleteSelecOne()"
                class="delete-btn mr-2 cursor-pointer"
                style="
                  width: 85px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                "
                id="delete-button"
                type="button"
              >
                DELETE
              </button>
            </div>
          </div>
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover"
          >
            <thead>
              <tr>
                <th class="head">
                  <div class="switchToggleRead" style="text-align: start">
                    <input
                      type="checkbox"
                      v-model="isChecked"
                      :true-value="true"
                      :false-value="false"
                      v-on:change="checkall(isChecked)"
                    />
                    Is Delete
                  </div>
                </th>
                <th
                  class="head sorting"
                  id="id-Client"
                  v-on:click="sorting('users.name', 'id-Client')"
                >
                  Client Name
                </th>
                <th
                  class="head sorting"
                  id="id-scripts"
                  v-on:click="sorting('instrument_token', 'id-scripts')"
                >
                  Symbol
                </th>
                <th
                  class="head sorting"
                  id="id-start_range"
                  v-on:click="sorting('start_range', 'id-start_range')"
                >
                  Rate Start
                </th>
                <th
                  class="head sorting"
                  id="id-end_range"
                  v-on:click="sorting('end_range', 'id-end_range')"
                >
                  Rate End
                </th>
                <th
                  class="head sorting"
                  id="id-type"
                  v-on:click="sorting('type', 'id-type')"
                >
                  LOT/QTY
                </th>
                <th
                  class="head sorting"
                  id="id-position_limit"
                  v-on:click="sorting('position_limit', 'id-position_limit')"
                >
                  Position Limit
                </th>
                <th
                  class="head sorting"
                  id="id-max_order"
                  v-on:click="sorting('max_order', 'id-max_order')"
                >
                  Max Order
                </th>
                <th
                  class="head sorting"
                  id="id-min_order"
                  v-on:click="sorting('min_order', 'id-min_order')"
                >
                  Min Order
                </th>
                <th
                  class="head sorting"
                  id="id-updated_at"
                  v-on:click="sorting('updated_at', 'id-updated_at')"
                >
                  Add Time
                </th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body" style="text-align: start">
                  <div class="switchToggleRead">
                    <input
                      :id="'index_' + data.id"
                      type="checkbox"
                      v-model="data.isChecked"
                      v-on:change="checkHandlling(data.isChecked)"
                      :true-value="true"
                      :false-value="false"
                    />
                  </div>
                </td>
                <td class="body">{{ data.user ? data.user.name : "" }}</td>
                <td class="body">
                  {{ data.instrument_token }}
                </td>
                <td class="body">{{ data.start_range }}</td>
                <td class="body">{{ data.end_range }}</td>
                <td class="body">{{ data.type }}</td>
                <td class="body">{{ data.position_limit }}</td>
                <td class="body">{{ data.max_order }}</td>
                <td class="body">{{ data.min_order }}</td>
                <td class="body">
                  {{ $helperService.getDateTime(data.updated_at) }}
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem"
    />
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    ErrorComponent,
    Form,
    Field,
    Multiselect,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      isChecked: false,
      type: "Default",
      keyword: "",
      list: [],
      detail: {
        exchange: "COMEX",
        start_range: "",
        end_range: "",
        position_limit: "",
        max_order: "",
        min_order: "",
        range: 0,
        instrument_token: "",
        type: "LOT",
      },
      client_list: [],
      script_list: [],
      exchanges: [
      {
          name: "COMEX",
          value: "COMEX",
        },
      ],
      fromDate: "",
      toDate: "",
    };
  },
  mounted() {
     this.checkAccess();
    this.getClientList();
    this.getScriptList();
  },
  methods: {
    checkAccess() {
      var access = JSON.parse(localStorage.getItem("access"));
      if (access.is_quantitySetting == 0) {
        this.$router.push("/access-denied");
      }
    },
    emptyValue() {
      this.detail.start_range = "";
      this.detail.end_range = "";
      this.detail.instrument_token = "";
    },
    getScriptList() {
      this.detail.instrument_token = "";
      this.script_list = [];
      var searchObj = {};
      searchObj.exchange = 'COMEX'
      this.$api
        .getAPI({
          _action: "/script-list-by-exchange",
          _body: searchObj,
        })
        .then((res) => {
          this.script_list.push({ instrument_token: "ALL", name: "ALL" });
          for (var i in res) {
            this.script_list.push(res[i]);
          }
          this.getList();
          // this.script_list = res;
        })
        .catch(() => {});
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            this.client_list[i].new_name =
              this.client_list[i].name + " (" + this.client_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          this.list[k].isChecked = true;
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = -1;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
        console.log("object", count);
        if (count == 0) {
          this.isChecked = true;
        }
      }
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by);
    },
    getList(orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.detail.user_ids) {
        searchObj.user_ids = this.detail.user_ids;
      }

      searchObj.exchange = 'COMEX'
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/quantity-setting-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          res.list = this.list;
          for (var i in this.list) {
            this.list[i].isChecked = false;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    save() {
      this.$api
        .postAPI({
          _action: "/quantity-setting",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
            var user_id = this.detail.user_ids;
            this.detail = {};
            this.detail.user_ids = user_id;
            this.detail.range = 0;
            this.getList();
          }
        })
        .catch(() => {});
    },
    deleteSelecOne() {
      this.$refs.errorComponent.updateFormLoader(true);
      var array = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          array.push(this.list[i].id);
        }
      }
      var obj = {};
      obj.ids = array;

      if (array.length > 0) {
        this.$api
          .postAPI({
            _action: "/delete-quantity-setting",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
              this.$refs.errorComponent.updateFormLoader(false);
              this.getList();
            }
          })
          .catch(() => {
            this.$refs.errorComponent.updateFormLoader(false);
          });
      } else {
        this.$notify({
          type: "error",
          text: "Select atleast on setting.",
        });
        this.$refs.errorComponent.updateFormLoader(false);
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
