<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600; font-size: 16px">
            Export Trades
          </h2>
          <form class="form-inline transparent-form p10 border-page mt-2">
            <div class="col-lg-12 row">
              <div
                class="col-2 mt-2"
                style="text-align: left"
                v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'">
                <Multiselect
                  mode="multiple"
                  trackBy="name"
                  label="name"
                  valueProp="server_code"
                  class="register-select"
                  placeholder="Super Master"
                  v-model="code"
                  :options="supermasterList"
                  searchable="true" />
              </div>
              <div class="col-lg-5 col-sm-3 row mt-1">
                <div class="col-lg-6 col-sm-3 mt-1">
                  <datepicker
                    v-model="fromDate"
                    class="form-control"
                    typeable="true"
                    inputFormat="dd-MM-yyyy"
                    placeholder="From Date"
                    style="width: 100% !important" />
                </div>
                <div class="col-lg-6 col-sm-3 mt-1">
                  <datepicker
                    v-model="toDate"
                    class="form-control"
                    inputFormat="dd-MM-yyyy"
                    placeholder="To Date"
                    typeable="true"
                    style="width: 100% !important" />
                </div>

                <!-- <div class="col-lg-3 col-sm-2 mt-1" style="margin-left: -40px">
                  <VueDatepicker
                    hide-input-icon
                    v-bind:clearable="false"
                    v-model="fromTime"
                    format="HH:mm:ss"
                    enable-seconds
                    textInput
                    time-picker
                    seconds-increment="1"
                    time-picker-inline
                    :is-24="true"
                    placeholder="To Time" />
                </div> -->
                <!-- time-picker -->
                <!-- <div class="col-lg-3 col-sm-2 mt-1" style="margin-left: -60px">
                  <VueDatepicker
                    hide-input-icon
                    v-bind:clearable="false"
                    v-model="toTime"
                    format="HH:mm:ss"
                    enable-seconds
                    textInput
                    time-picker
                    seconds-increment="1"
                    time-picker-inline
                    :is-24="true"
                    placeholder="From Time" />
                </div> -->
              </div>
              <div class="col-lg-5 col-sm-12 row mt-2 justify-content-start">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList()"
                  class="btn btn-primary mb-2">
                  Export
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <PrintSettlement ref="printSettlement"></PrintSettlement>
    <PrintSummaryReport ref="printSummaryReport"></PrintSummaryReport>
    <ErrorComponent @retry="getList()" ref="errorComponent" />
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/ErrorComponent";
import PrintSettlement from "../../components/PrintSettlement.vue";
import PrintSummaryReport from "../../components/PrintSummaryReport.vue";
import Datepicker from "vue3-datepicker";
// import VueDatepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
export default {
  components: {
    ErrorComponent,
    PrintSettlement,
    PrintSummaryReport,
    Multiselect,
    Datepicker,
    // VueDatepicker,
  },
  name: "TradeSummaryReport",
  data() {
    return {
      loader: true,
      role: localStorage.getItem("role"),
      list: [],
      fromDate: new Date(),
      toDate: new Date(),
      fileHeader:
        "segment,clientcode,scriptname,ex_date,ce_pe,strike,buysell,lot,qty,rate,trade_date,trade_time,order_no,trade_no",
      excel_data: [],
      supermasterList: [],
      id: "",
      code: [],
      user_id: "",
      broker_id: "",
      master_id: "",
      gross_mtm: 0,
      brokerage: 0,
      net_mtm: 0,
      downline: 0,
      upline: 0,
      self: 0,
    };
  },
  computed: {
    userType() {
      return this.$store.getters.getUser?.role == "BROKER"
        ? this.$store.getters.getUser?.broker_type
        : this.$store.getters.getUser?.role;
    },
    userRole() {
      return this.$store.getters.getUser?.role;
    },
    userId() {
      return this.$store.getters.getUser?.role == "SUPER_MASTER"
        ? this.$store.getters.getUser?.server_code
        : this.$store.getters.getUser?.id;
    },
    sharing() {
      return this.$store.getters.getUser?.sharing;
    },
    isGrossSharing() {
      return this.$store.getters.getUser?.is_sharing_gross;
    },
    brokerList() {
      if (this.master_id) {
        var temp = [];
        for (var broker of this.broker_list) {
          if (broker.master_id == this.master_id) {
            temp.push(broker);
          }
        }

        return temp;
      }

      return this.broker_list;
    },
    clientList() {
      var temp = [];
      if (this.master_id && this.broker_id) {
        for (var client of this.client_list) {
          if (
            client.master_id == this.master_id &&
            (this.broker_id == client.broker_id ||
              this.broker_id == client.broker_id_2 ||
              this.broker_id == client.broker_id_3)
          ) {
            temp.push(client);
          }
        }

        return temp;
      } else if (this.master_id) {
        for (var client1 of this.client_list) {
          if (client1.master_id == this.master_id) {
            temp.push(client1);
          }
        }

        return temp;
      } else if (this.broker_id) {
        for (var client2 of this.client_list) {
          if (
            this.broker_id == client2.broker_id ||
            this.broker_id == client2.broker_id_2 ||
            this.broker_id == client2.broker_id_3
          ) {
            temp.push(client2);
          }
        }

        return temp;
      }

      return this.client_list;
    },
  },

  mounted() {
    // this.checkAccess();
    this.getSuperMasterList(0);
    // this.getClientList();
    // this.getBrokerList();
    // this.getMasterList();
  },
  methods: {
    async fetchData() {
      return this.excel_data;
    },
    getSuperMasterList() {
      this.$api
        .getAPI({
          _action: "/super-master-list",
        })
        .then((res) => {
          this.supermasterList = res;
        })
        .catch(() => {});
    },
    getList(orderBy, sortBy) {
      if (!this.code) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please select Super Master.",
        });
        return;
      }
      if (!this.fromDate) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please select From Date.",
        });
        return;
      }
      if (!this.toDate) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please select To Date.",
        });
        return;
      }
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      // searchObj.day = "TODAY";
      if (this.fromDate) {
        if (this.fromTime) {
          searchObj.from_date =
            moment(this.fromDate).format("YYYY-MM-DD") +
            " " +
            moment(this.fromTime).format("HH:mm:ss");
        } else {
          searchObj.from_date =
            moment(this.fromDate).format("YYYY-MM-DD") + " 00:00:00";
        }

        searchObj.day = "";
      }
      if (this.toDate) {
        if (this.toTime) {
          searchObj.to_date =
            moment(this.toDate).format("YYYY-MM-DD") +
            " " +
            moment(this.toTime).format("HH:mm:ss");
        } else {
          searchObj.to_date =
            moment(this.toDate).format("YYYY-MM-DD") + " 23:59:59";
        }
        searchObj.day = "";
      }

      if (this.trade_type) {
        searchObj.trade_action_type = this.trade_type;
      }
      if (this.name) {
        searchObj.name = this.name;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.authority_id) {
        searchObj.authority_id = this.authority_id;
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      if (this.user_id) {
        searchObj.user_id = this.user_id;
      }
      if (this.exchange) {
        searchObj.exchange = this.exchange;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      } else {
        searchObj.order_by = "desc";
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      } else {
        searchObj.sort_by = "trade_date";
      }
      searchObj.trade_status = "EXECUTED";
      searchObj.server_code = this.code;
      ("");
      this.searchObj = searchObj;
      this.$api
        .getAPI({
          _action: "/trade-exports",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          for (var i in this.list) {
            var type = this.list[i].type.split(",");
            this.list[i].new_type = type[0];
            this.list[i].type = type[1];
            this.list[i].isChecked = false;
            this.list[i].isEdit = false;
            this.list[i].lot = this.$helperService.getFormattedPrice(
              this.list[i].lot
            );
            this.list[i].price = this.$helperService.getFormattedPriceCmx(
              this.list[i].price
            );
            this.list[i].quantity = this.$helperService.getFormattedPrice(
              this.list[i].quantity
            );
          }
          this.count = this.list.length;
          this.excel_data = [];
          if (res.length > 0) {
            for (var j in res) {
              var year = moment(new Date()).format("YYYY");
              if (res[j].script && res[j].script.expiry) {
                year = moment(res[j].script.expiry, "YYYY-MM-DD").format(
                  "YYYY"
                );
              }

              console.log(res[j].trading_symbol.split(" ")[1]);
              var obj = "";
              obj += res[j].exchange
                ? res[j].exchange
                    .replace("MCX", "MCXFUT")
                    .replace("NFO", "NSEFUT")
                : "";
              obj += ",";
              obj += res[j].user ? res[j].user.name : "";
              obj += "(" + (res[j].user ? res[j].user.code : "") + ")";
              obj += ",";
              obj += res[j].trading_symbol
                ? res[j].trading_symbol.split(" ")[0]
                : "";
              obj += ",";
              obj +=
                res[j].trading_symbol && res[j].trading_symbol.split(" ")[1]
                  ? moment(
                      res[j].trading_symbol.split(" ")[1] + year,
                      "DDMMMYYYY"
                    )
                      .format("DD-MMM-YY")
                      .toLocaleUpperCase()
                  : "";
              obj += ",";
              obj += ",";
              obj += ",";
              obj += res[j].order_action;
              obj += ",";
              obj += res[j].lot;
              obj += ",";
              obj += res[j].quantity;
              obj += ",";
              obj += res[j].price;
              obj += ",";
              obj += this.$helperService.getCustomDateYearNew(
                res[j].trade_date
              );
              obj += ",";
              obj += this.$helperService.getTime(res[j].trade_date);
              obj += ",";
              obj += res[j].id;
              obj += ",";
              obj += res[j].id;
              this.excel_data.push(obj);
            }
          }
          setTimeout(() => {
            this.download();
          }, 1000);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              { list: this.list },
              "No Data available"
            );
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    download() {
      let filename = "Trades-Book.csv";
      let text = this.fileHeader;
      console.log("excel_data", this.excel_data);
      for (var i in this.excel_data) {
        text += "\n";
        text += this.excel_data[i];
      }
      let blob = new Blob([text], { type: "text/csv" });
      let link = document.createElement("a");
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }, 100);
    },
  },
};
</script>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
