<template>
  <div>
    <div class="container form-signin3">
      <div class="card-bg col-md-12 dealer-form form-signin3 mt-0">
        <div class="container">
          <div class="col-md-12 row">
            <!-- <div class="col-6" style="justify-content: left; display: flex">
              <h3 style="color: rgb(29, 68, 91); font-weight: 600">
                Basic Details
              </h3>
            </div> -->
          </div>
          <div>
            <div>
              <div class="">
                <Form ref="account" @submit="save()">
                  <div class="col-12 form row mb-2 border-page">
                    <div
                      class="col-12 bg-transparent card-header text-left"
                      style="margin: 0; padding: 9px">
                      <h5
                        style="color: black !important; margin: 0; padding: 0">
                        Brokerage
                      </h5>
                    </div>
                    <div
                      class="col-2"
                      style="text-align: left"
                      v-if="user_detail.role == 'USER' || !user_detail.role">
                      <label class="col-form-label" style="display: flex">
                        Client
                        <span class="text-danger">*</span>
                      </label>
                      <Multiselect
                        mode="single"
                        trackBy="name"
                        label="name"
                        :disabled="client_id"
                        valueProp="id"
                        class="register-select"
                        placeholder=" Client"
                        v-model="details.user_id"
                        :options="client_list"
                        @select="brokerageList(details.user_id)"
                        searchable="true" />
                    </div>

                    <div
                      class="col-2"
                      style="text-align: left"
                      v-if="user_detail.role == 'MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Client
                        <span class="text-danger">*</span>
                      </label>
                      <Multiselect
                        mode="single"
                        trackBy="name"
                        label="name"
                        :disabled="client_id"
                        valueProp="id"
                        class="register-select"
                        placeholder=" Client"
                        v-model="details.user_id"
                        :options="masterList"
                        @select="brokerageList(details.user_id)"
                        searchable="true" />
                    </div>
                    <div
                      class="col-2"
                      style="text-align: left"
                      v-if="user_detail.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Client
                        <span class="text-danger">*</span>
                      </label>
                      <Multiselect
                        mode="single"
                        trackBy="name"
                        label="name"
                        :disabled="client_id"
                        valueProp="id"
                        class="register-select"
                        placeholder=" Client"
                        v-model="details.user_id"
                        :options="supermasterList"
                        @select="brokerageList(details.user_id)"
                        searchable="true" />
                    </div>
                    <div
                      class="col-2"
                      style="text-align: left"
                      v-if="user_detail.role == 'BROKER'">
                      <label class="col-form-label" style="display: flex">
                        Client
                        <span class="text-danger">*</span>
                      </label>
                      <Multiselect
                        mode="single"
                        trackBy="name"
                        label="name"
                        :disabled="client_id"
                        valueProp="id"
                        class="register-select"
                        placeholder=" Client"
                        v-model="details.user_id"
                        :options="brokerList"
                        @select="brokerageList(details.user_id)"
                        searchable="true" />
                    </div>

                    <div
                      class="col-1"
                      style="justify-content: flex-start; display: grid">
                      <label class="col-form-label" style="display: flex">
                        % (Wise)
                      </label>
                      <input
                        id="percent_margin"
                        style="width: 13px"
                        type="checkbox"
                        name="brokerage_type"
                        v-model="details.brokerage_type"
                        true-value="PERCENT" />
                    </div>
                    <div
                      class="col-1"
                      style="justify-content: flex-start; display: grid">
                      <label class="col-form-label" style="display: flex">
                        Fix
                      </label>
                      <input
                        id="fix_margin"
                        style="width: 13px"
                        type="checkbox"
                        name="brokerage_type"
                        v-model="details.brokerage_type"
                        true-value="FIX" />
                    </div>
                    <div
                      class="col-1"
                      style="justify-content: flex-start; display: grid">
                      <label class="col-form-label" style="display: flex">
                        Script Wise
                      </label>
                      <input
                        style="width: 13px"
                        id="is_script_wise"
                        type="checkbox"
                        name="name_is_script_wise"
                        v-model="details.is_script_wise"
                        :true-value="1"
                        :false-value="0" />
                    </div>
                    <div
                      class="col-5"
                      style="text-align: left"
                      v-if="details.is_script_wise">
                      <label class="col-form-label" style="display: flex">
                        Script
                        <span class="text-danger">*</span>
                      </label>
                      <Multiselect
                        :mode="id ? 'single' : 'tags'"
                        trackBy="name"
                        label="name"
                        valueProp="name"
                        class="multi-select"
                        style="
                          max-height: 200px !important;
                          min-height: 34px !important;
                        "
                        placeholder="Script"
                        v-model="details.instrument_tokens"
                        :options="script_list"
                        searchable="true" />
                    </div>
                    <!-- <div
                      class="col-1"
                      style="justify-content: flex-start; display: grid">
                      <label class="col-form-label" style="display: flex">
                        Positional Brokerage
                      </label>
                      <input
                        id="positional_brokerage"
                        style="width: 13px"
                        type="checkbox"
                        name="positional_brokerage"
                        v-model="details.positional_brokerage"
                        :true-value="1"
                        :false-vaue="0"
                         />
                    </div> -->
                  </div>

                  <div class="col-12 row border-page">
                    <div
                      class="col-12 bg-transparent card-header text-left"
                      style="height: 50px !important">
                      <h5
                        style="color: black !important; margin: 0; padding: 0">
                        Client Brokerage
                        <span
                          class="text-danger"
                          style="font-size: 20px !important"
                          >*</span
                        >
                      </h5>
                    </div>
                    <div class="col-12 row">
                      <div class="col-4 row mt-3 mb-2">
                        <label class="col-form-label" style="display: flex">
                          {{ details.brokerage_type == "FIX" ? "Fix" : "%" }}
                          Delivery
                          <span class="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          class="form-control"
                          name="delivery_brokerage"
                          placeholder="0"
                          autocomplete="off"
                          :validateOnInput="true"
                          :rules="
                            details.brokerage_type == 'FIX'
                              ? 'required'
                              : 'required|checkMax1'
                          "
                          v-model="details.delivery_brokerage"
                          v-on:keypress="
                            $helperService.allowDecimalValueNew($event)
                          " />
                        <p
                          style="
                            text-align: left;
                            color: black;
                            font-size: 13px;
                            display: flex;
                            min-width: 100%;
                            margin-top: 10px;
                          ">
                          <ErrorMessage
                            name="delivery_brokerage"
                            class="text-danger" />
                        </p>
                        <span
                          v-if="details.delivery_brokerage"
                          style="
                            text-align: left;
                            color: black;
                            font-size: 13px;
                            display: flex;
                            min-width: 100%;
                          ">
                          {{
                            $helperService.convert(details.delivery_brokerage)
                          }}
                        </span>
                      </div>
                      <div class="col-4 row mt-3 ml-2 mb-2">
                        <label class="col-form-label" style="display: flex">
                          {{ details.brokerage_type == "FIX" ? "Fix" : "%" }}
                          Intraday
                          <span class="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          class="form-control"
                          name="intraday_brokerage"
                          placeholder="0"
                          v-model="details.intraday_brokerage"
                          :rules="
                            details.brokerage_type == 'FIX'
                              ? 'required'
                              : 'required|checkMax1'
                          "
                          v-on:keypress="
                            $helperService.allowDecimalValueNew($event)
                          " />
                        <p
                          style="
                            text-align: left;
                            color: black;
                            font-size: 13px;
                            display: flex;
                            min-width: 100%;
                            margin-top: 10px;
                          ">
                          <ErrorMessage
                            name="intraday_brokerage"
                            class="text-danger" />
                        </p>
                        <span
                          v-if="details.intraday_brokerage"
                          style="
                            text-align: left;
                            color: black;
                            font-size: 13px;
                            display: flex;
                          ">
                          {{
                            $helperService.convert(details.intraday_brokerage)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-md-12 text-center"
                    v-if="role != 'USER' && role != 'BROKER'">
                    <button
                      class="save-btn px-4 ml-3"
                      type="submit"
                      id="save-button">
                      SAVE
                    </button>
                    <button
                      class="save-btn px-4 ml-3"
                      style="background: #5a6268 !important"
                      type="button"
                      id="save-button"
                      v-on:click="goBack">
                      BACK
                    </button>
                  </div>
                  <div class="col-md-12 text-right"></div>
                </Form>
              </div>
            </div>
            <div class="col-md-12 bg-light sticky-table-header">
              <table
                id="table"
                style="margin-top: 16px"
                data-toggle="table"
                data-search="true"
                data-filter-control="true"
                data-toolbar="#toolbar"
                class="table table-sm text-center text-white table-bordered product-list table-hover">
                <thead>
                  <tr>
                    <th class="head">Client Name</th>
                    <th class="head">Script Wise</th>
                    <th class="head">% Wise</th>
                    <th class="head">Fix</th>
                    <th class="head">Positional Brokerage</th>
                    <th class="head">Add Time</th>
                    <!-- <th class="head">View</th> -->
                    <th class="head" v-if="role != 'USER' && role != 'BROKER'">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="body">
                      {{
                        data.user
                          ? $helperService.getUpperCase(
                              data.user.code + "-" + data.user.name
                            )
                          : ""
                      }}
                    </td>
                    <td class="body">
                      {{
                        data.instrument_token != 0
                          ? data.instrument_token
                          : "NO"
                      }}
                    </td>
                    <td class="body">
                      {{
                        data.brokerage_type == "PERCENT"
                          ? data.delivery_brokerage
                          : "0"
                      }}
                    </td>
                    <td class="body">
                      {{
                        data.brokerage_type == "FIX"
                          ? data.delivery_brokerage
                          : "NO"
                      }}
                    </td>
                    <td class="body">
                      {{ data.positional_brokerage == 1 ? "YES" : "NO" }}
                    </td>

                    <td class="body">
                      {{ $helperService.getDateTime(data.updated_at) }}
                    </td>
                    <!-- <td class="body">
                      <a
                        style="color: #c97fc7; cursor: pointer"
                        v-on:click="$router.push('/edit-brokerage/' + data.id)"
                      >
                        View
                      </a>
                    </td> -->
                    <td class="body" v-if="role != 'USER' && role != 'BROKER'">
                      <a
                        v-on:click="showDeletePopup(data)"
                        class="fa fa-trash text-danger"
                        style="cursor: pointer"
                        title="Delete"></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetails()" ref="errorComponent" />
  </div>
</template>
<script>
// ErrorMessage
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import moment from "moment";
import swal from "sweetalert2";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    Multiselect,
  },
  watch: {
    "$route.fullPath"() {
      this.$refs.account.resetForm();
      this.id = "";
    },
  },
  name: "Profile",
  data() {
    return {
      id: this.$route.params.id,
      client_id: this.$route.params.client_id,
      role: localStorage.getItem("role"),
      details: {
        exchange: "COMEX",
        user_id: "",
        instrument_token: "",
        instrument_tokens: [],
        is_percent: 1,
        is_script_wise: false,
        brokerage_type: "PERCENT",
      },
      client_list: [],
      list: [],
      masterList: [],
      supermasterList: [],
      brokerList: [],
      script_list: [],
      user_detail: {},
      exchanges: [
        {
          name: "COMEX",
          value: "COMEX",
        },
      ],
    };
  },
  mounted() {
    this.getScriptList('COMEX')
    if (this.id) {
      this.getDetails();
    }
    if (this.client_id) {
      this.details.user_id = this.client_id;
      this.getMasterList(0);
      this.getBrokerList(0);
      this.getSuperMasterList(0);
      this.brokerageList(this.client_id);
    }
    this.getClientList();
  },
  methods: {
    showDeletePopup(data) {
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to Brokerage",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/brokerage/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  this.brokerageList(this.details.user_id);
                }
              })
              .catch(() => {});
          } else {
            this.brokerageList(this.details.user_id);
          }
        });
    },
    brokerageList(id) {
      if (!this.id) {
        (this.details.instrument_tokens = []), (this.details.is_percent = 1);
        this.details.brokerage_type = "PERCENT";
        this.details.instrument_token = "";
        this.details.is_script_wise = false;
        if (this.details.delivery_brokerage) {
          this.details.delivery_brokerage = "";
          this.details.intraday_brokerage = "";
          var $this = this;
          setTimeout(() => {
            $this.resetForm();
          }, 100);
        }
      }
      this.list = [];
      var searchObj = {};
      if (id) {
        searchObj.id = id;
      }
      this.$api
        .getAPI({
          _action: "/brokerage-list",
          _body: searchObj,
        })
        .then((res) => {
          this.list = res;
        })
        .catch(() => {});
    },
    getSuperMasterList() {
      this.$api
        .getAPI({
          _action: "/super-master-list",
        })
        .then((res) => {
          this.supermasterList = res;
          for (var i in this.supermasterList) {
            {
              if (this.details.user_id == this.supermasterList[i].id) {
                this.user_detail = this.supermasterList[i];
              }
            }
          }
        })
        .catch(() => {});
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          this.brokerList = res;
          for (var i in this.brokerList) {
            {
              if (this.details.user_id == this.brokerList[i].id) {
                this.user_detail = this.brokerList[i];
              }
            }
          }
        })
        .catch(() => {});
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.masterList = res;
          for (var i in this.masterList) {
            {
              if (this.details.user_id == this.masterList[i].id) {
                this.user_detail = this.masterList[i];
              }
            }
          }
        })
        .catch(() => {});
    },
    getScriptList(exchange) {
      this.script_list = [];
      this.$refs.errorComponent.updateFormLoader(true);
      var searchObj = {};
      if (exchange) {
        searchObj.exchange = exchange;
      }
      this.$api
        .getAPI({
          _action: "/script-list-by-exchange",
          _body: searchObj,
        })
        .then((res) => {
          this.script_list = res;
          this.$refs.errorComponent.updateFormLoader(false);
        })
        .catch(() => {});
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            {
              if (this.details.user_id == this.client_list[i].id) {
                this.user_detail = this.client_list[i];
              }
            }
          }
        })
        .catch(() => {});
    },
    getDetails() {
      if (this.id) {
        this.$refs.errorComponent.updateFormLoader(true);
        this.$api
          .getAPI({
            _action: "/brokerage/" + this.id,
          })
          .then((res) => {
            res.expire_date = moment(res.expire_date).toDate();
            res.join_date = moment(res.join_date).toDate();
            res.instrument_tokens = [];
            if (res.instrument_token) {
              res.instrument_tokens.push(res.instrument_token);
            }

            this.details = res;
            this.brokerageList(this.details.user_id);
            this.getScriptList(this.details.exchange);
            this.getMasterList(0);
            this.getBrokerList(0);
            this.getSuperMasterList(0);
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      }
    },
    resetForm() {
      this.$refs.account.resetForm();
    },
    save() {
      if (!this.details.is_script_wise) {
        this.details.instrument_token = "";
      }

      var list = [...this.details.instrument_tokens];
      this.saveApi(0, list);
    },
    saveApi(index, list) {
      if (index < list.length || list.length == 0) {
        this.details.instrument_token = list.length > 0 ? list[index] : "";
        if (this.id) {
          this.$api
            .putAPI({
              _action: "/edit-brokerage",
              _body: this.details,
              _buttonId: "save-button",
            })
            .then((res) => {
              if (index == list.length - 1 || list.length == 0) {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });

                  this.brokerageList(this.details.user_id);
                } else {
                  this.saveApi(++index, list);
                }
              }
            })
            .catch(() => {});
        } else {
          this.$api
            .postAPI({
              _action: "/add-brokerage",
              _body: this.details,
              _buttonId: "save-button",
            })
            .then((res) => {
              if (index == list.length - 1 || list.length == 0) {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  this.brokerageList(this.details.user_id);
                  // this.$router.go(-1);
                  // this.$router.push("/brokerages");
                }
              } else {
                this.saveApi(++index, list);
              }
            })
            .catch(() => {});
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 40px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
  font-size: 0.675rem !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
